import React from 'react'
import { SubHeading } from '../../components'
// import { images } from '../../constants'
import Ubication from '../../components/Map/Ubication'
import styles from './FindUs.module.css'

const FindUs = () => (
  <div className={styles.main_container} id='contact'>
    <div className={styles.info_container}>
      <SubHeading title='Contacto' />
      <h1 className='headtext__cormorant' style={{ marginBottom: '3rem' }}>Encuéntranos</h1>
      <div className='app__wrapper-content'>
        <p className='p__opensans'>Av.Napo y Espejo, Diagonal al Parque Central.</p>
        <p className='p__cormorant' style={{ color: '#DCCA87', margin: '2rem 0' }}>Horario de Atención</p>
        <p className='p__opensans'>Lunes - Viernes: 10:00 am - 02:00 am</p>
        <p className='p__opensans'>Sábado - Domingo: 10:00 am - 03:00 am</p>
      </div>
      <button type='button' className='custom__button' style={{ marginTop: '2rem' }}>Visítanos</button>
    </div>

    <Ubication />
  </div>
)

export default FindUs
