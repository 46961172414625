import { MapContainer, TileLayer, Popup, Marker } from 'react-leaflet'
import { images } from '../../constants'

export default function Ubication () {
  return (
    <MapContainer
      style={{ width: '100%', height: '100%' }}
      center={[-0.473097, -76.983803]}
      zoom={30}
    >
      <TileLayer url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png' />
      <Marker position={[-0.473097, -76.983803]}>
        <Popup>
          <div>
            <img src={images.banner} height='100px' alt='chef_image' />
          </div>
        </Popup>
      </Marker>
    </MapContainer>
  )
}
