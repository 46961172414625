import images from './images'

const wines = [
  {
    title: 'Pilsener',
    price: '$3',
    tags: '1lt'
  },
  {
    title: 'Pilsener',
    price: '$3',
    tags: '1lt'
  },
  {
    title: 'Pilsener',
    price: '$3',
    tags: '1lt'
  },
  {
    title: 'Pilsener',
    price: '$3',
    tags: '1lt'
  },
  {
    title: 'Pilsener',
    price: '$3',
    tags: '1lt'
  }
]

const cocktails = [
  {
    title: 'De Carne',
    price: '$2',
    tags: '1 Pincho de Carne | 1 Gaseosa'
  },
  {
    title: 'De Camarón',
    price: '$2',
    tags: '1 Pincho de Camarón | 1 Gaseosa'
  },
  {
    title: 'De Pollo',
    price: '$3',
    tags: '1 Pincho de Pollo | 1 Gaseosa'
  },
  {
    title: 'Combo Familiar',
    price: '$3',
    tags: '3 Pinchos de Carne | 1 Coca Cola 2lts | Ensalada'
  },
  {
    title: 'Combo Amigos',
    price: '$3',
    tags: '3 Pinchos de Pollo | 1 Coca Cola 2lts | Ensalada'
  }
]

const awards = [
  {
    imgUrl: images.award02,
    title: 'Bib Gourmond',
    subtitle: 'Lorem ipsum dolor sit amet, consectetur.'
  },
  {
    imgUrl: images.award01,
    title: 'Rising Star',
    subtitle: 'Lorem ipsum dolor sit amet, consectetur.'
  },
  {
    imgUrl: images.award05,
    title: 'AA Hospitality',
    subtitle: 'Lorem ipsum dolor sit amet, consectetur.'
  },
  {
    imgUrl: images.award03,
    title: 'Outstanding Chef',
    subtitle: 'Lorem ipsum dolor sit amet, consectetur.'
  }
]

export default { wines, cocktails, awards }
