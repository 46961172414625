import React from 'react'
import { FiFacebook, FiTwitter, FiInstagram } from 'react-icons/fi'

// import { FooterOverlay, Newsletter } from '../../components';
import { images } from '../../constants'
import './Footer.css'

const Footer = () => (
  <div className='app__footer section__padding' id='login'>
    {/* <FooterOverlay /> */}
    {/* <Newsletter /> */}

    <div className='app__footer-links'>
      <div className='app__footer-links_contact'>
        <h1 className='app__footer-headtext'>Contáctanos</h1>
        <p className='p__opensans'>Av.Napo y Espejo</p>
        <p className='p__opensans'>+5939441230</p>
        <p className='p__opensans'>+5939125553</p>
      </div>

      <div className='app__footer-links_logo'>
        <img src={images.banner} alt='footer_logo' />
        <p className='p__opensans'>&quot;La comida es una forma de conectarnos con los demás y crear recuerdos inolvidables.&quot;</p>
        <img src={images.spoon} className='spoon__img' style={{ marginTop: 15 }} />
        <div className='app__footer-links_icons'>
          <FiFacebook />
          <FiTwitter />
          <FiInstagram />
        </div>
      </div>

      <div className='app__footer-links_work'>
        <h1 className='app__footer-headtext'>Horario de Atención</h1>
        <p className='p__opensans'>Lunes-Viernes:</p>
        <p className='p__opensans'>08:00 am - 12:00 am</p>
        <p className='p__opensans'>Sábado-Domingo:</p>
        <p className='p__opensans'>07:00 am - 11:00 pm</p>
      </div>
    </div>

    <div className='footer__copyright'>
      <p className='p__opensans'>2022 Anderson Grefa. All Rights reserved.</p>
    </div>

  </div>
)

export default Footer
