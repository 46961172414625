import React from 'react'

import { SubHeading } from '../../components'
import { images } from '../../constants'
import './Header.css'

const Header = () => (
  <div className='app__header app__wrapper section__padding' id='home'>
    <div className='app__wrapper_info'>
      <SubHeading title='Pinchos para todos los gustos' />
      <h1 className='pTitle'>LOS PINCHOS DEL MALECON</h1>
      <p className='p__opensans' style={{ margin: '2rem 0' }}>Si estás buscando los mejores de la ciudad, ¡Has venido al lugar correcto! En nuestro restaurante de pinchos, ofrecemos una amplia variedad de opciones preparadas con ingredientes frescos y de alta calidad para satisfacer a todos los gustos. ¡Ven a disfrutar de nuestros deliciosos pinchos! Te esperamos.</p>
      {/* <button type='button' className='custom__button'>Ver el Menu</button> */}
    </div>

    <div className='app__wrapper_img'>
      <img draggable='false' src={images.pinchoCamarones} alt='header_img' />
    </div>
  </div>
)

export default Header
