import React from 'react'

import { images } from '../../constants'
import './AboutUs.css'

const AboutUs = () => (
  <div className='app__aboutus app__bg flex__center section__padding' id='about'>

    <div className='app__aboutus-content flex__center'>
      <div className='app__aboutus-content_about'>
        <h1 className='headtext__cormorant'>Misión</h1>
        <img src={images.spoon} alt='about_spoon' className='spoon__img' />
        <p className='p__opensans'>Ofrecer a los clientes una experiencia única y memorable a través de la preparación y presentación de pinchos de alta calidad y sabor excepcional.</p>
        <h1 className='headtext__cormorant'>Visión</h1>
        <img src={images.spoon} alt='about_spoon' className='spoon__img' />
        <p className='p__opensans'>Convertirnos en el restaurante de pinchos más reconocido y valorado de la ciudad,
          ofreciendo una amplia variedad de opciones de pinchos y un servicio excepcional a cada cliente.
        </p>
      </div>

      <div className='app__aboutus-content_knife flex__center'>
        <img src={images.knife} draggable='false' alt='pinchos' />
      </div>

      <div className='app__aboutus-content_history'>
        <h1 className='headtext__cormorant'>Objetivos</h1>
        <img src={images.spoon} alt='about_spoon' className='spoon__img' />
        <ul>
          <p className='p__opensans'>Utilizar ingredientes frescos y de calidad para garantizar la máxima satisfacción del cliente</p>
          <p className='p__opensans'>Crear un ambiente acogedor y agradable para disfrutar de la comida.</p>
        </ul>
      </div>
    </div>
  </div>
)

export default AboutUs
