import React from 'react'
import { GiHamburgerMenu } from 'react-icons/gi'
import { MdOutlineRestaurantMenu } from 'react-icons/md'
import { useScroll } from './useScroll'
import images from '../../constants/images'
import './Navbar.css'

const Navbar = () => {
  const [toggleMenu, setToggleMenu] = React.useState(false)

  const { scrollDirection } = useScroll()
  const styles = {
    active: {
      visibility: 'visible',
      transition: 'all 0.5s'
    },
    hidden: {
      visibility: 'hidden',
      transition: 'all 0.5s',
      transform: 'translateY(-100%)'
    }
  }

  return (
    <nav className='app__navbar' style={scrollDirection === 'down' ? styles.active : styles.hidden}>
      <div className='app__navbar-logo'>
        <img src={images.banner} alt='app__logo' />
      </div>
      <ul className='app__navbar-links'>
        <li className='p__opensans'><a href='#home'>Inicio</a></li>
        <li className='p__opensans'><a href='#about'>Acerca</a></li>
        <li className='p__opensans'><a href='#menu'>Menu</a></li>
        {/* <li className="p__opensans"><a href="#awards">Awards</a></li> */}
        <li className='p__opensans'><a href='#contact'>Contacto</a></li>
      </ul>
      <div className='app__navbar-login'>
        <a href='#login' className='p__opensans'>Registrarse</a>
        <div />
        <a href='#menu' className='p__opensans'>Ver menú</a>
      </div>
      <div className='app__navbar-smallscreen'>
        <GiHamburgerMenu color='#fff' fontSize={27} onClick={() => setToggleMenu(true)} />
        {toggleMenu && (
          <div className='app__navbar-smallscreen_overlay flex__center slide-bottom'>
            <MdOutlineRestaurantMenu fontSize={27} className='overlay__close' onClick={() => setToggleMenu(false)} />
            <ul className='app__navbar-smallscreen_links'>
              <li><a href='#home' onClick={() => setToggleMenu(false)}>Inicio</a></li>
              <li><a href='#about' onClick={() => setToggleMenu(false)}>Acerca</a></li>
              <li><a href='#menu' onClick={() => setToggleMenu(false)}>Menu</a></li>
              {/* <li><a href="#awards" onClick={() => setToggleMenu(false)}>Awards</a></li> */}
              <li><a href='#contact' onClick={() => setToggleMenu(false)}>Contacto</a></li>
            </ul>
          </div>
        )}
      </div>
    </nav>
  )
}

export default Navbar
